























































































































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'uciPrelab2Question1',
  components: {
    NumberValue,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molFraction: null,
        boilingPoint: null,
        massPercent: null,
        volPercent: null,
      },
      options: [
        {text: 'Much less than 100 °C', value: 'muchLess100'},
        {text: 'Slightly less than 100 °C', value: 'bitLess100'},
        {text: 'Equal to 100 °C', value: 'equalTo100'},
        {text: 'Greater than 100 °C but less than 254 °C', value: 'between100and254'},
        {text: 'Equal to 254 °C', value: 'equalTo254'},
        {text: 'A bit more than 254 °C', value: 'bitMore254'},
        {text: 'Much more than 254 °C', value: 'muchMore254'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/eugenol.png';
    },
  },
});
